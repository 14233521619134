@use '../../../globalStyles/mixins' as m;
@use '../../../globalStyles/variables' as v;

.education {
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: v.$border-radius;
  box-sizing: border-box;
  gap: 1rem;

  .item__details {
    width: 100%;

    .title {
      font-size: m.size(large-0);
      font-weight: m.weight(medium);
      margin: 0 0 0.25rem 0;
    }

    .term {
      font-size: m.size(small-0);
      margin-top: 10px;
    }

    .description {
      color: m.color(grey-8);
    }
  }

  &::before {
    content: '';
    width: 5px;
    background: m.color(grey-4);
  }
}
